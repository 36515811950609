/*import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {DOM, globals} from './_variables';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);*/


export default {


  ShowHideElements: () => {
    (function (window, $) {


      $('.ham').click(function () {
        $(this).toggleClass('open');
        $('.burger-menu').toggleClass('open');
      });




    })(window, jQuery);
  },


  accordions: () => {
    const accordions = DOM.html.querySelectorAll('.accordion');

    if (accordions.length) {
      for (const accordion of accordions) {
        const
            title = accordion.querySelector('.accordion__title'),
            collapsible = accordion.querySelector('.accordion__collapsible')
        let isOpen = false

        title.addEventListener('click', () => {
          if (!isOpen) {
            gsap.to(collapsible, {
              onStart: () => {
                title.classList.add('accordion__title--active')
              },
              height: 'auto'
            })
          } else {
            gsap.to(collapsible, {
              onStart: () => {
                title.classList.remove('accordion__title--active')
              },
              height: 0
            })
          }

          isOpen = !isOpen
        })
      }
    }
  }
}


