import Glide from '@glidejs/glide'
import {DOM} from "./_variables";

export default {
    galleries: () => {

      const sliders = document.querySelectorAll('.glide');

      if (sliders.length) {
        for (const slider of sliders) {

          const glide = new Glide(slider, {
            type: 'carousel',
            perView: 1,
            focusAt: 0,
            startAt: 0,
            gap: 0
          })

          glide.mount()
        }

      }
    }
}
